@charset "utf-8";
/* ===================================================================
CSS information
file name  : _settings.scss
=================================================================== */
$experimental-support-for-svg: true;

//----------------------------------------------//
//  Media Queries								//
//----------------------------------------------//
$bp_tab: 1199px; //tab
$bp_sp: 767px; //sp

@mixin mq($device, $val: null, $val2: null) {
  @if $device == pc {
    //pc
    @media only screen and (min-width: $bp_tab + 1) {
      @content;
    }
    @media print {
      @content;
    }
  } @else if $device == pc_s {
    //pc_s
    @media only screen and (min-width: $bp_tab + 1) and (max-width: ($bp_tab + 1) + (1920 - ($bp_tab + 1)) / 2) {
      @content;
    }
  } @else if $device == pc_l {
    //pc_l
    @media only screen and (min-width: ($bp_tab + 1) + (1920 - ($bp_tab + 1)) / 2 + 1) {
      @content;
    }
    @media print {
      @content;
    }
  } @else if $device == tab {
    //tab
    @media only screen and (min-width: $bp_sp + 1) and (max-width: $bp_tab) {
      @content;
    }
  } @else if $device == tab_l {
    //tab_l
    @media only screen and (min-width: ($bp_tab - $bp_sp) / 2 + $bp_sp + 1) and (max-width: $bp_tab) {
      @content;
    }
  } @else if $device == tab_s {
    //tab_s
    @media only screen and (min-width: $bp_sp + 1) and (max-width: ($bp_tab - $bp_sp) / 2 + $bp_sp) {
      @content;
    }
  } @else if $device == sp {
    //sp
    @media only screen and (max-width: $bp_sp) {
      @content;
    }
  } @else if $device == sp_l {
    //sp_l
    @media only screen and (min-width: ($bp_sp - 320) / 2 + 320 + 1) and (max-width: $bp_sp) {
      @content;
    }
  } @else if $device == sp_s {
    //sp_s
    @media only screen and (max-width: ($bp_sp - 320) / 2 + 320) {
      @content;
    }
  } @else if $device == pc_tab {
    //pc & tab
    @media only screen and (min-width: $bp_sp + 1) {
      @content;
    }
  } @else if $device == tab_sp {
    //tab & sp
    @media only screen and (max-width: $bp_tab) {
      @content;
    }
  } @else if $device == min_w {
    //max-width
    @media only screen and (min-width: $val) {
      @content;
    }
  } @else if $device == max_w {
    //min-width
    @media only screen and (max-width: $val) {
      @content;
    }
  } @else if $device == min_max_w {
    //min-width ~ max-width
    @media only screen and (min-width: $val) and (max-width: $val2) {
      @content;
    }
  } @else if $device == min_h {
    //max-height
    @media only screen and (min-height: $val) {
      @content;
    }
  } @else if $device == max_h {
    //min-height
    @media only screen and (max-height: $val) {
      @content;
    }
  } @else if $device == min_max_h {
    //min-height ~ max-height
    @media only screen and (min-height: $val) and (max-height: $val2) {
      @content;
    }
  }
}

//----------------------------------------------//
//  Variable									//
//----------------------------------------------//

$columnWidth: 1280;

$baseSize: 14;

$baseSizeSp: 12;

$baseColor: #000;
$shadowWhite: white;
$shadowGray: #c8d0e7;
$bgGray: #e4ebf5;
$txtPurple: #6d5dfc;
$primary-color: #50a3a2;
//----------------------------------------------//
//  Function									//
//----------------------------------------------//
@function wCon($width, $parentWidth: $columnWidth) {
  @return $width / $parentWidth * 100%;
}

//----------------------------------------------//
//  Placeholder									//
//----------------------------------------------//

%bw {
  width: 90%;
  max-width: $columnWidth + px;
  margin: auto;
  @include mq(tab_sp) {
    width: 880 / 960 * 100%;
  }
}
%bw_l {
  width: 90%;
  margin: auto;
  @include mq(pc) {
    padding: 0 50px;
  }
  @include mq(tab_sp) {
    width: 880 / 960 * 100%;
  }
}

%cf {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

//----------------------------------------------//
//  mixin										//
//----------------------------------------------//

@mixin fsz($size: $baseSize, $parent: null) {
  $i: $size * (100 / $baseSize);

  @if $parent == null {
    font-size: ceil($i * 1%);
  } @else {
    font-size: ceil(100% * ($size / $parent));
  }
}
@mixin fsz-cha($pc_fsz: 30, $tab_fsz: 30, $sp_fsz: 17) {
  @include fsz($pc_fsz);
  @include mq(tab) {
    @include fsz($tab_fsz);
  }
  @include mq(sp) {
    @include fsz($sp_fsz);
  }
}

@mixin cV($pro, $v1: 0, $v2: 0, $v3: null) {
  #{$pro}: $v1;
  @if $v3 == null {
    @include mq(sp) {
      #{$pro}: $v2;
    }
  } @else {
    @include mq(tab) {
      #{$pro}: $v2;
    }
    @include mq(sp) {
      #{$pro}: $v3;
    }
  }
}

@mixin hover() {
  .mouse &:hover,
  .touch &.touchstart {
    @content;
  }
  .touch & {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &.touchend {
      @include transition-delay(200ms);
    }
  }
}

@mixin zoomImg($mag: 140%, $cri: center) {
  overflow: hidden;
  img {
    position: relative;
    width: $mag;
    max-width: none;
    @if $cri == center {
      margin-left: -($mag - 100) / 2;
    }
    @if $cri == right {
      margin-left: -($mag - 100);
    }
  }
}

@mixin reverseDecoration {
  text-decoration: none !important;
  @include hover {
    text-decoration: underline !important;
  }
}

@mixin gradient($c1: #000, $c2: #fff) {
  @include background(linear-gradient($c1, $c2));
  @include filter-gradient($c1, $c2);
}

@mixin over {
  .mouse & {
    @include transition(opacity 0.3s ease-in);
  }
  @include hover {
    @include opacity(0.7);
  }
}

@mixin icon($type: 'fa') {
  @if $type == 'fa' {
    font-family: 'Font Awesome 5 Pro';
  } @else if $type == 'ion' {
    font-family: 'Ionicons';
  }
}

@mixin text_abr($line: '2', $line-height: '1.5') {
  @if $line == '1' {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
    line-height: $line-height;
    .firefox &,
    .ie & {
      max-height: $line * $line-height * 1em;
    }
  }
}

@mixin vertical($pos: '') {
  writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -moz-writing-mode: vertical-rl;
  -o-writing-mode: vertical-rl;
  -webkit-writing-mode: vertical-rl;
  -webkit-text-orientation: upright;
  -moz-text-orientation: upright;
  -ms-text-orientation: upright;
  text-orientation: upright;
  text-align: left;
  display: inline-block;
  font-feature-settings: normal;
  @if $pos == 'default' {
    writing-mode: lr-tb;
    writing-mode: horizontal-tb;
    -moz-writing-mode: horizontal-tb;
    -o-writing-mode: horizontal-tb;
    -webkit-writing-mode: horizontal-tb;
    -webkit-text-orientation: inherit;
    -moz-text-orientation: inherit;
    -ms-text-orientation: inherit;
    text-orientation: inherit;
  }
}
