@import '../../scss/mixins';

.header {
  @include cV(width, wCon(73, 1120), 7.5%, 99%);
  @include cV(z-index, 1, 2, 2);
  text-align: center;
  opacity: 1;
  visibility: visible;
  border-radius: 4px;
  margin-right: 10px;
  position: relative;
  top: 15px;
  &.active {
    opacity: 1 !important;
    visibility: visible !important;
  }
  @include mq(tab_s) {
    width: 94%;
    margin-right: 0;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50.5%);
  }
  @include mq(sp) {
    width: 100%;
    margin-right: 0;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .top__menu {
    width: 100%;
    font-size: 0;
    box-shadow: 0.3rem 0.3rem 0.6rem $shadowGray, -0.2rem -0.2rem 0.5rem $shadowWhite;
    @include cV(border-radius, 1rem, 0.7rem, 0);
    background-color: $bgGray;
    ul {
      padding: 0.5rem;
      position: relative;
      @include mq(tab_s) {
        display: flex;
        justify-content: space-between;
      }
      @include mq(sp) {
        display: flex;
        justify-content: space-between;
      }
      li {
        display: block;
        transition: color 0.3s ease 0s;
        @include mq(tab_s) {
          width: 25%;
        }
        @include mq(sp) {
          width: 25%;
        }
        &:not(:last-child) {
          @include cV(margin, 0 0 0.5rem, 0 0.5rem 0 0);
          @include mq(tab_s) {
            margin: 0 0.5rem 0 0;
          }
          @include mq(sp) {
            margin: 0;
          }
        }
        a {
          position: relative;
          overflow: hidden;
          @include cV(height, 72px, 45px);
          color: #171717;
          text-align: center;
          cursor: pointer;
          @include cV(padding, 1rem, 0.5rem);
          display: block;
          transition-delay: 0s;
          transition-duration: 0.5s;
          @include cV(border-radius, 0.75rem, 0.5rem, 0.5rem);
          cursor: pointer;
          z-index: 1;
          @include mq(tab_s) {
            height: 45px;
            padding: 0.5rem;
          }
          @include mq(sp) {
            height: 45px;
            padding: 0.5rem;
          }
          .icon {
            margin: -27px 0 0 -20px;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            font-size: 20px;
            color: #171717;
            line-height: 40px;
            border-radius: 30px;
            transition: all 0.3s ease 0s;
          }
          .link {
            position: absolute;
            left: 0;
            top: 57%;
            width: 100%;
            display: block;
            font-size: 11px;
            font-weight: 500;
            transition: all 0.3s ease 0s;
          }
        }
        &.active {
          a {
            &,
            .icon,
            .link {
              color: $primary-color;
              transition-delay: 0.25s;
              transition-duration: 0.5s;
            }
          }
        }
        &:not(.active) {
          &:hover {
            a {
              @include mq(pc) {
                box-shadow: inset 0.2rem 0.2rem 0.5rem $shadowGray, inset -0.2rem -0.2rem 0.5rem $shadowWhite;
              }
            }
          }
        }
        &.menu__fx {
          position: absolute;
          box-shadow: inset 0.15em 0.15em 0.15em $shadowWhite, inset -0.15em -0.15em 0.15em $shadowGray,
            0.3rem 0.3rem 0.6rem $shadowGray, -0.3rem -0rem 0.6rem $shadowWhite;
          border-radius: 0.75rem;
          opacity: 0;
          @include cV(width, calc(100% - 1rem), 25%);
          top: 0.5rem;
          transition: all 0.3s ease;
          z-index: 0;
          @include mq(tab_s) {
            transform: translateX(-1.2rem);
          }
          @include mq(sp) {
            transform: translateX(0);
          }
        }
      }
    }
  } //.top__menu
  .profile {
    padding: 10px 62px 10px 18px;
    width: auto;
    height: 64px;
    text-align: left;
    display: none;
    .image {
      margin-right: 14px;
      float: left;
      width: 44px;
      font-size: 0;
      img {
        width: 100%;
        height: auto;
        border-radius: 100%;
      }
    }
    .title {
      font-size: 15px;
      color: #323232;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .subtitle {
      font-size: 12px;
      color: #78cc6d;
      line-height: 14px;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  } //.profile
}
