@import '../../scss/mixins';

.card__started {
  position: relative;
  @include cV(width, wCon(480, 1120), 100%);
  height: 600px;
  @include cV(z-index, #{var(--index)}, unset, unset);
  box-shadow: 0.3rem 0.3rem 0.6rem $shadowGray, -0.2rem -0.2rem 0.5rem $shadowWhite;
  border-radius: 1rem;
  background-color: $bgGray;
  overflow: hidden;
  @include mq(tab_s) {
    width: 99%;
    height: 570px;
    margin-bottom: 1.5rem;
  }
  @include mq(sp) {
    margin: 0.5rem 0 1.5rem;
  }
  // &::before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
  //     border-radius: 0 0 4px 0;
  // }
  // &::after {
  //     content: '';
  //     position: absolute;
  //     left: -15px;
  //     top: -15px;
  //     width: 100%;
  //     height: 100%;
  //     background: linear-gradient(
  //         135deg,
  //         rgba(120, 204, 109, 0.4) 0%,
  //         rgba(120, 204, 109, 0.01) 100%
  //     );
  //     border-radius: 4px;
  // }
  .profile {
    @include cV(padding, 220px 50px 50px, 120px 50px 50px);
    position: relative;
    height: 100%;
    text-align: center;
    border-radius: 4px;
    &.no-photo {
      padding-top: 340px;
    }
    .slide {
      position: absolute;
      overflow: hidden;
      left: 0;
      top: 0;
      width: 100%;
      @include cV(height, 300px, 250px);
      background-color: #626262;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 4px 4px 0 0;
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: -25%;
        @include cV(bottom, -198px, -170px);
        width: 700px;
        height: 200px;
        background: $bgGray;
        transform: rotate(12deg);
        @include mq(tab_s) {
          bottom: -170px;
        }
      }
      &::after {
        left: auto;
        right: -25%;
        @include cV(transform, rotate(-6deg), rotate(-10deg));
        @include mq(tab_s) {
          transform: rotate(-10deg);
        }
      }
    } //.slide
    .image {
      @include cV(margin, 0 0 40px, 0 0 60px);
      position: relative;
      display: inline-block;
      width: 140px;
      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: -10px;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, rgba(120, 204, 109, 0.4) 0%, rgba(120, 204, 109, 0.01) 100%);
        border-radius: 100%;
      }
      img {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        border: 3px solid #ffffff;
        border-radius: 100%;
      }
    } //.image
    .title {
      font-size: 32px;
      color: #171717;
      line-height: 32px;
      font-weight: 400;
    }
    .subtitle {
      margin: 20px 0;
      font-size: 14px;
      color: $primary-color;
      line-height: 14px;
      font-weight: 400;
    }
    .social {
      a {
        margin: 0 6px;
        display: inline-block;
        vertical-align: middle;
        .ion,
        .fab,
        .fas {
          font-size: 16px;
          color: #171717;
          transition: all 0.3s ease 0s;
        }
        &:hover {
          .ion,
          .fab,
          .fas {
            color: #78cc6d;
          }
        }
      }
    }
    .btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      a {
        display: flex;
        flex: 1;
        text-decoration: none;
        padding: 20px;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        border-top: 1px solid #ddd;
        transition: all 0.3s ease;
        i {
          margin-left: 15px;
        }
        &:not(:last-child) {
          border-right: 1px solid #ddd;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
  } //.profile
}
