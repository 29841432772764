@import '../../scss/mixins';

.slide_item {
  position: relative;
  @include cV(border-radius, 1.5rem, 0.8rem);
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    padding-bottom: calc((650 / 1130) * 100%);
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
