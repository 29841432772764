@import '../../scss/mixins';

.form_group {
  display: flex;
  @include mq(sp) {
    flex-direction: column;
  }
  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
  > input,
  select,
  textarea {
    padding: 0.7rem 0.5rem;
    border-radius: 0.4rem;
    border: 1px solid $shadowGray;
    background: $shadowWhite;
    outline: none !important;
    &[type='submit'] {
      cursor: pointer;
      padding: 1rem 2.5rem;
      border-color: $primary-color;
      background: $primary-color;
      color: #fff;
      transition: all 0.3s ease-out;
      margin: auto;
      @include cV(width, (280/13.333) + rem, 100%);
      &:hover {
        background: $shadowWhite;
        color: $primary-color;
      }
    }
    option {
      padding: 0.7rem 0.5rem;
    }
  }
  textarea {
    resize: vertical;
  }
  &__item {
    flex: 1;
    &:not(:first-child) {
      @include cV(margin, 0 0 0 0.8rem, 0.8rem 0 0);
    }
    &__label {
      @include cV(max-width, 6rem, 100%);
      display: flex;
      align-items: center;
    }
  }
}

.info__list {
  margin: 1rem 0 3rem;
  tr {
    th,
    td {
      text-align: left;
      .contact__lnk {
        text-decoration: underline;
      }
    }
    &:not(:first-child) {
      th,
      td {
        padding-top: 0.5em;
      }
    }
  }
}
