@import '../../scss/mixins';

.card__inner {
  padding: 0;
  overflow: hidden;
  left: 0;
  top: 15px;
  @include cV(width, wCon(555, 1120), 100%);
  height: 570px;
  background: #ffffff;
  transform-origin: center left;
  opacity: 0;
  visibility: hidden;
  display: none;
  @include cV(z-index, 1, unset, unset);
  box-shadow: 0.3rem 0.3rem 0.6rem $shadowGray, -0.2rem -0.2rem 0.5rem $shadowWhite;
  @include cV(border-radius, 0 1rem 1rem 0, 1rem);
  background-color: $bgGray;
  transition: all 1s linear;
  position: relative;
  @include mq(tab_s) {
    width: 99%;
    border-radius: 1rem;
    top: unset;
    left: unset;
    margin-bottom: 1.5rem;
    display: block !important;
    visibility: visible;
    opacity: 1;
    height: auto;
  }
  @include mq(sp) {
    width: 100%;
    border-radius: 1rem;
    top: unset;
    left: unset;
    margin-bottom: 1.5rem;
    display: block !important;
    visibility: visible;
    opacity: 1;
    height: auto;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    // z-index: 10;
    display: block;
  }
  .card__wrap {
    @include cV(padding, 2rem, 1rem);
    position: relative;
    overflow-x: hidden;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title,
      .text__box {
        margin-bottom: 20px;
        line-height: 1.5;
      }
      .text__box {
        margin-bottom: 30px;
        line-height: 1.5;
      }
      .info__list {
        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          strong {
            display: flex;
            align-items: flex-end;
            flex: auto;
            &::after {
              content: '';
              display: block;
              flex: auto;
              height: 1px;
              border-bottom: 1px dashed;
              margin: 0 15px 4px;
            }
          }
        }
      }
    }
  }
}

@keyframes innOpen {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  25% {
    transform: translateX(-100%);
    opacity: 0.5;
  }
  50% {
    transform: translateX(-200%);
    opacity: 0;
    width: 0;
  }
  75% {
    transform: translateX(-100%);
    opacity: 0.5;
    width: 0;
  }
  100% {
    transform: translateX(0);
    width: wCon(555, 1120);
    opacity: 1;
  }
}
