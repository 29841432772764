@import '../../scss/mixins';

.card__column {
  width: 50%;
  float: left;
  padding: 3%;
  box-sizing: border-box;
  &.right {
    padding-top: 60px;
  }
  .card {
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    // width: 95%;
    // max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: $shadowGray;
    color: #fff;
    cursor: pointer;
    margin-bottom: 40px;
    border-radius: 10px;
    transition: opacity 200ms linear 320ms, transform 200ms ease-out 320ms;
    .border {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 6px;
      border: 1px solid #fff;
      opacity: 0.5;
      left: -6px;
      top: -6px;
      border-radius: 15px;
      transition: opacity 200ms linear, transform 200ms ease-out;
    }
    img {
      width: 90%;
      position: absolute;
      top: -6%;
      left: -6%;
      border-radius: 15px;
      transition: opacity 200ms linear 0ms, transform 200ms ease-in 0ms;
      aspect-ratio: 4/3;
    }
    h3 {
      position: absolute;
      width: calc(100% - 0.5rem);
      font-size: 100%;
      @include text_abr(2, 1.2);
      font-weight: normal;
      left: 0.5rem;
      bottom: 1rem;
    }
    &.clicked {
      img {
        transform: translate3d(0px, -40px, 0px);
        opacity: 0;
      }
      .border {
        opacity: 0;
        transform: scale(1.3);
      }
    }
    &.out {
      &,
      img {
        transform: translate3d(0px, -40px, 0px);
        opacity: 0;
      }
    }
    &.out,
    &.clicked {
      h3 {
        transform: translate3d(20%, -40px, 0px);
        opacity: 0;
      }
    }
  }
}

.cover {
  position: fixed;
  background: rgba($shadowGray, 0.8);
  z-index: 9;
  transform-origin: 50% 50%;
  transition: transform 300ms ease-in-out;
  left: var(--left);
  top: var(--top);
  width: var(--width);
  height: var(--height);
  transform: scaleX(#{var(--sX)}) scaleY(#{var(--sY)}) translate3d(#{var(--oX)}, #{var(--oY)}, 0px);
}
.modal {
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: var(--opModal);
  transition: opacity 200ms linear 0ms;
  overflow: auto;
  &__inner {
    position: absolute;
    width: 90%;
    max-width: 1280px;
    min-height: 80%;
    top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    @include cV(border-radius, 1.5rem, 0.8rem);
    @include cV(margin-bottom, 1rem, 0.5rem);
    padding-bottom: 2rem;
    @include mq(tab) {
      top: 7rem;
    }
    @include mq(min_max_w, 600px, 767px) {
      top: 7rem;
    }
  }
  &__slider {
    @include cV(width, 90%, 85%);
    max-width: 900px;
    margin: 0 auto;
    @include cV(transform, translateY(-4rem), translateY(-25%));
  }
  &__content {
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
    @include cV(transform, translateY(-2rem), translateY(-1.5rem));
    &__name {
      font-size: 1.5rem;
      line-height: 1.3;
      @include cV(margin-bottom, 1rem, 0.5rem);
    }
    &__detail {
      line-height: 1.5;
      & + .modal__content__detail {
        margin-top: 1.5rem;
      }
    }
  }
  .close__content {
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 2rem;
    height: 2rem;
    z-index: 11;
    span {
      background: #222;
      width: 2rem;
      height: 3px;
      display: block;
      position: absolute;
      top: 1rem;
      &.x-1 {
        transform: rotate(45deg);
      }
      &.x-2 {
        transform: rotate(-45deg);
      }
    }
  }
}

.info__list {
  margin-top: 1.5rem;
  border-collapse: collapse;
  width: 100%;
  tr {
    th,
    td {
      text-align: left;
      padding: 0.4em 0;
      line-height: 1.3;
      font-size: 100%;
      .contact__lnk {
        text-decoration: underline;
      }
    }
    &:not(:first-child) {
      th,
      td {
        border-top: 1px solid $shadowGray;
      }
    }
    td {
      @include cV(padding-left, 2rem, 1rem);
    }
  }
}
