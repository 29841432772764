@import '../../scss/mixins';

.react-slideshow-container .default-nav {
  @include cV(width, 40px, 30px);
  @include cV(height, 40px, 30px);
  border: 1px solid $shadowGray;
  svg {
    width: 15px;
  }
  &:first-of-type {
    transform: translateX(-50%);
  }
  &:last-of-type {
    transform: translateX(50%);
  }
}
.react-slideshow-container + ul.indicators {
  margin-top: -1rem;
  z-index: 1;
  position: relative;
  li {
    padding: 8px;
  }
}
