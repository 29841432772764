.text__box {
  margin-top: 2rem;
  p {
    line-height: 1.5;
    & + p {
      margin-top: 1rem;
    }
    img {
      width: 100%;
      border-radius: 0.5rem;
    }
  }
}
.date {
  opacity: 0.7;
}
.subject {
  font-weight: 700;
  font-size: 1.2rem;
}
