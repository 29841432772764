@import '../../scss/mixins';

.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  &__item {
    padding-left: 40px;
    position: relative;
    &:not(.period) {
      padding-top: 10px;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 25px);
        height: calc(100% - 10px);
        border-radius: 0.7rem;
        z-index: -1;
        background-color: rgb(220 231 242);
      }
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__info {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin: 0 0 0.5em 0;
    white-space: nowrap;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.3);
  }
  &__marker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15px;
    &:before {
      background: $primary-color;
      border-radius: 100%;
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    &:after {
      content: '';
      width: 2px;
      background: #ccd5db;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .timeline__item:last-child &:after {
      content: none;
    }
  }
  &__content {
    padding-bottom: 20px;
    p:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    margin-bottom: 0.2rem;
  }

  .period {
    padding: 0;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(241, 244, 249, 0), rgb(228 235 245));
    }
    .timeline__info {
      display: none;
    }
    .timeline__marker {
      &:before {
        background: transparent;
        content: '';
        width: 15px;
        height: auto;
        border: none;
        border-radius: 0;
        top: 0;
        bottom: 50px;
        position: absolute;
        border-top: 3px solid #ccd5db;
        border-bottom: 3px solid #ccd5db;
      }
      &:after {
        content: '';
        height: 50px;
        top: auto;
      }
    }
    .timeline__content {
      padding: 20px 0 70px;
    }
    .timeline__title {
      margin: 0;
    }
  }
}
