.info__list {
  margin-bottom: 1rem;
  tr {
    th,
    td {
      text-align: left;
      .contact__lnk {
        text-decoration: underline;
      }
    }
    &:not(:first-child) {
      th,
      td {
        padding-top: 0.5em;
      }
    }
  }
}

.text__box {
  p {
    line-height: 1.5;
    & + p {
      margin-top: 1rem;
    }
  }
}
