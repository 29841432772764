@import '../../scss/mixins';
.sending_container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($shadowGray, 0.5);
    z-index: 0;
  }
  .sending {
    position: absolute;
    width: 800px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
    user-select: none;
    z-index: 1;
    span {
      position: absolute;
      width: 40px;
      height: 40px;
      animation: move 4s linear infinite;
      transform: rotate(180deg);
      color: $primary-color;
      font-weight: 900;
      font-size: 1.5rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &:nth-child(2) {
        animation-delay: 0.3s;
      }
      &:nth-child(3) {
        animation-delay: 0.5s;
      }
      &:nth-child(4) {
        animation-delay: 0.7s;
      }
      &:nth-child(5) {
        animation-delay: 0.9s;
      }
      &:nth-child(6) {
        animation-delay: 1.1s;
      }
      &:nth-child(7) {
        animation-delay: 1.3s;
      }
    }
  }
}

@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  35% {
    left: 41%;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    left: 59%;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    left: 100%;
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}
