@import url('https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0;
  margin: 0;
  user-select: none;
}
body {
  margin: 0;
  padding: 0;
  border: none;
  font-family: 'Comfortaa', 'Poppins', 'Smooch Sans', sans-serif;
  font-size: 16px;
  color: #626262;
  background: #d8dbe2;
  letter-spacing: 0em;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
