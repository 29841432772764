.type__text {
  position: relative;
  &::after {
    content: '';
    border-right: 1px solid currentColor;
    animation: typeEffect 0.75s infinite;
  }
}

@keyframes typeEffect {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
