@import 'mixins';

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#tsparticles {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1;
  // background: #50a3a2;
  // background: -webkit-linear-gradient(top left, #50a3a2 0%, #78cc6d 100%);
  // background: linear-gradient(to bottom right, #50a3a2 0%, #78cc6d 100%);
  background: linear-gradient(to bottom, rgba(241, 244, 249, 0.55), rgba(223, 241, 255, 0.55));
}

.container {
  @extend %bw;
  max-width: 1120px;
  @include cV(height, 600px, auto);
  transition: all 0.4s ease 0s;
  display: flex;
  @include mq(tab) {
    width: 95%;
  }
  @include mq(tab_s) {
    flex-direction: column;
    height: auto;
    padding: 80px 0 40px;
  }
  @include mq(sp) {
    flex-direction: column;
    width: 95%;
    padding-bottom: 65px;
  }
}

a {
  color: $primary-color;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
}
